<template>
  <p>
    Legal
  </p>
</template>

<script>

export default {
  name: 'Legal',
  components: {
  },
  mounted() {
  }
}
</script>

<style>
</style>
