<template>
  <section class="page-title-section style-five">
    <div class="left-color-layer" />
    <div class="right-color-layer" />
    <div
      class="icon-layer-one"
      :style="{'background-image': `url(${require('@/assets/images/icons/icon-1.png')})`}"
    />
    <div
      class="icon-layer-two"
      :style="{'background-image': `url(${require('@/assets/images/icons/icon-4.png')})`}"
    />
    <div
      class="icon-layer-three"
      :style="{'background-image': `url(${require('@/assets/images/icons/icon-1.png')})`}"
    />
    <div
      class="icon-layer-four"
      :style="{'background-image': `url(${require('@/assets/images/icons/icon-12.png')})`}"
    />
    <div
      class="icon-layer-five"
      :style="{'background-image': `url(${require('@/assets/images/icons/icon-10.png')})`}"
    />
    <div class="auto-container">
      <div class="content-boxed">
        <div class="inner-box">
          <h2>{{ $t('pages.tncs.title') }}</h2>
        </div>
      </div>
    </div>
  </section>
    
  <section class="contact-page-section">
    <div class="auto-container">
      <div class="inner-container">
        <div class="row clearfix">
          <div class="form-block col-lg-12 col-md-12 col-sm-12">
            <div class="inner-column">
              <div class="title-box">
                <div class="text">
                  n/a
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>

export default {
  name: 'Tncs',
  components: {
  },
  mounted() {
  }
}
</script>

<style scoped>
.contact-page-section .inner-container:before {
  display: none;
}
</style>
