<template>
  <p>
    ManagedKubernetes
  </p>
</template>

<script>

export default {
  name: 'ManagedKubernetes',
  components: {
  },
  mounted() {
  }
}
</script>

<style>
</style>
