<template>
  <header class="main-header">
    <div class="header-upper">
      <div class="auto-container clearfix">
        <div class="pull-left logo-box">
          <div class="logo">
            <router-link to="/">
              <img
                src="@/assets/images/logo.svg"
                alt="dotManaged Logo"
                title="dotManaged Logo"
              >
            </router-link>
          </div>
        </div>
				
        <div class="nav-outer nav-outer-mobile clearfix">
          <div class="mobile-nav-toggler">
            <span class="icon flaticon-menu" />
          </div>
        </div>
        <div class="nav-outer clearfix">
          <nav class="main-menu navbar-expand-md">
            <div class="navbar-header">
              <button
                class="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span class="icon-bar" />
                <span class="icon-bar" />
                <span class="icon-bar" />
              </button>
            </div>
						
            <div
              id="navbarSupportedContent"
              class="navbar-collapse collapse clearfix"
            >
              <ul class="navigation clearfix">
                <li :class="{'current': isCurrent('/')}">
                  <router-link to="/">
                    Home
                  </router-link>
                </li>
                <li
                  class="dropdown"
                  :class="{'current': isCurrent('/about')}"
                >
                  <router-link to="/about">
                    {{ $t('pages.about.link-title') }}
                  </router-link>
                  <ul>
                    <li>
                      <router-link to="/about/team">
                        {{ $t('pages.team.link-title') }}
                      </router-link>
                    </li>
                    <li>
                      <router-link to="/about/pricing">
                        {{ $t('pages.pricing.link-title') }}
                      </router-link>
                    </li>
                    <li>
                      <router-link to="/about/testimonials">
                        {{ $t('pages.testimonials.link-title') }}
                      </router-link>
                    </li>
                  </ul>
                </li>
                <li
                  class="dropdown"
                  :class="{'current': isCurrent('/consulting')}"
                >
                  <a>
                    {{ $t('pages.consulting.link-title') }}
                  </a>
                  <ul>
                    <li>
                      <router-link to="/consulting/it-analysis">
                        {{ $t('pages.it-analysis.link-title') }}
                      </router-link>
                    </li>
                    <li>
                      <router-link to="/consulting/devops">
                        {{ $t('pages.devops.link-title') }}
                      </router-link>
                    </li>
                    <li>
                      <router-link to="/consulting/it-administration">
                        {{ $t('pages.it-administration.link-title') }}
                      </router-link>
                    </li>
                    <li>
                      <router-link to="/consulting/it-project-engineering">
                        {{ $t('pages.it-project-engineering.link-title') }}
                      </router-link>
                    </li>
                  </ul>
                </li>
                <li
                  class="dropdown"
                  :class="{'current': isCurrent('/cloud-services')}"
                >
                  <a>
                    {{ $t('pages.cloud-services.link-title') }}
                  </a>
                  <ul>
                    <li>
                      <router-link to="/cloud-services/domain-registration-api">
                        {{ $t('pages.domain-registration-api.link-title') }}
                      </router-link>
                    </li>
                  </ul>
                </li>
                <li
                  class="dropdown"
                  :class="{'current': isCurrent('/managed-services')}"
                >
                  <a>
                    {{ $t('pages.managed-services.link-title') }}
                  </a>
                  <ul>
                    <li>
                      <router-link to="/managed-services/kubernetes">
                        {{ $t('pages.managed-kubernetes.link-title') }}
                      </router-link>
                    </li>
                    <li>
                      <router-link to="/managed-services/plesk">
                        {{ $t('pages.managed-plesk.link-title') }}
                      </router-link>
                    </li>
                  </ul>
                </li>
                <li
                  class="dropdown"
                  :class="{'current': isCurrent('/legal')}"
                >
                  <a>
                    {{ $t('pages.legal.link-title') }}
                  </a>
                  <ul>
                    <li>
                      <router-link to="/legal/tncs">
                        {{ $t('pages.tncs.link-title') }}
                      </router-link>
                    </li>
                    <li>
                      <router-link to="/legal/imprint">
                        {{ $t('pages.imprint.link-title') }}
                      </router-link>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </nav>
          <div class="outer-box clearfix">
            <ul class="language-nav">
              <li
                v-for="locale in locales"
                :key="locale"
                :class="{ 'active': locale == currentLocale }"
                @click="switchLocale(locale)"
              >
                <a>{{ locale }}</a>
              </li>
            </ul>
            <div class="btn-box">
              <router-link
                to="/contact"
                class="btn-style-one theme-btn header-btn"
              >
                <span class="txt"><i class="flaticon-message" />{{ $t('page-header.contact-button') }}</span>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="sticky-header">
      <div class="auto-container clearfix">
        <div class="logo pull-left">
          <a
            href="index.html"
            title=""
          ><img
            src="@/assets/images/logo.svg"
            alt="dotManaged Logo"
            title="dotManaged Logo"
          ></a>
        </div>
        <div class="pull-right">
          <nav class="main-menu">
            <!--Keep This Empty / Menu will come through Javascript-->
          </nav>
          <div class="outer-box clearfix">
            <ul class="language-nav">
              <li
                v-for="locale in locales"
                :key="locale"
                :class="{ 'active': locale == currentLocale }"
                @click="switchLocale(locale)"
              >
                <a>{{ locale }}</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="mobile-menu">
      <div class="menu-backdrop" />
      <div class="close-btn">
        <span class="icon flaticon-multiply" />
      </div>
      
      <nav class="menu-box">
        <div class="nav-logo">
          <a href="index.html"><img
            src="@/assets/images/logo.svg"
            alt=""
            title=""
          ></a>
        </div>
        <div class="menu-outer">
          <!--Here Menu Will Come Automatically Via Javascript / Same Menu as in Header-->
        </div>
      </nav>
    </div>
  </header>
</template>

<script>
export default {
  name: 'PageHeader',
  props: {},
  data() {
	  	return {
		  	locales: process.env.VUE_APP_I18N_SUPPORTED_LOCALE.split(','),
		  	currentLocale: this.$i18n.locale
	  	}
  },
  computed: {
    currentPage() {
      return this.$route.path;
    }
  },
  methods: {
    switchLocale(newLocale) {
		  	this.$i18n.locale = newLocale
			this.currentLocale = this.$i18n.locale
			localStorage.setItem('locale', newLocale)
	 	},
    isCurrent(path) {
      return (this.currentPage == path || this.currentPage.startsWith(path + "/"));
    }
  }
}
</script>

<style scoped>

	.nav-outer-mobile {
		position: absolute !important;
		right: 10px;
		top: 50%;
		transform: translateY(-50%);
	}
  .pull-left img {
    float: left;
  }
</style>
