<template>
  <section class="testimonial-section-two">
    <div class="color-layer" />
    <div
      class="circle-layer"
      :style="{'background-image': `url(${require('@/assets/images/background/pattern-28.png')})`}"
    />
    <div class="auto-container">
      <div class="row clearfix">
        <div class="title-column col-lg-6 col-md-12 col-sm-12">
          <div class="inner-column">
            <div class="quote-icon flaticon-quote-2">
              <span class="circle" />
            </div>
            <h2>{{ $t("components.testimonial-carousel.title") }}</h2>
            <div class="text">
              {{ $t("components.testimonial-carousel.text") }}
            </div>
          </div>
        </div>
				
        <div class="carousel-column col-lg-6 col-md-12 col-sm-12">
          <div class="inner-column">
            <div
              class="pattern-layer-one"
              :style="{'background-image': `url(${require('@/assets/images/background/pattern-27.png')})`}"
            />
            <div class="testimonial-carousel-four owl-carousel owl-theme">
              <div
                v-for="testimonial in testimonials"
                :key="testimonial.author"
                class="testimonial-block-two"
              >
                <div class="inner-box">
                  <div class="author-image">
                    <img
                      :src="`${require('@/assets/images/clients/' + testimonial.image)}`"
                      alt=""
                    >
                  </div>
                  <div class="content">
                    <h5>{{ testimonial.author }}</h5>
                    <div class="designation">
                      {{ testimonial.company }}
                    </div>
                    <div class="text">
                      {{ testimonial.message[$i18n.locale] }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>

export default {
  name: 'TestimonialCarousel',
  components: {
  },
  data() {
      return {
          testimonials: [
              {
                  author: "Tobias Roloff",
                  company: "AMS Gruppe",
                  message: {
                      de: "Langjährige hervorragende Zusammenarbeit. Immer ein Gewinn Herrn Mäuer im Team zu haben. Bei zukünftigen Projekten jederzeit wieder.",
                      en: "Many years of excellent cooperation. Always a benefit to have Mr. Mäuer in the team. Anytime again for future projects."
                  },
                  image: "ams-gruppe.jpeg"
              },
              {
                  author: "Luca Koroll",
                  company: "Marketing-Experte",
                  message: {
                      de: "Top Premium Hosting-Dienstleister aus Berlin. Schneller und freundlicher Kundensupport, der kompetent auf Probleme reagiert. Ich bin bereits seit 2014 glücklicher Kunde und kann mir keinen besseren Hosting-Provider vorstellen. Denn es werden auch individuelle Lösungen für Probleme gefunden - man ist mehr als nur eine Ticketnummer!",
                      en: "Top premium hosting service provider from Berlin. Fast and friendly customer support that reacts competently to problems. I'm already a happy customer since 2014 and can't imagine a better hosting provider. Because individual solutions for problems are also found - you are more than just a ticket number!"
                  },
                  image: "LucaKoroll-Logo-BlackTransp-medium.png"
              }
          ]
      };
  },
  mounted() {
    initializeJQuery();
  }
}
</script>

<style>
</style>
