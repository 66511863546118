<template>
  <section class="banner-section-four">
    <div
      class="pattern-layer-one"
      :style="{'background-image': `url(${require('@/assets/images/main-slider/pattern-9.png')})`}"
    />
    <div
      class="pattern-layer-two"
      :style="{'background-image': `url(${require('@/assets/images/main-slider/pattern-10.png')})`}"
    />
    <div class="color-layer" />
    <div class="auto-container">
      <div class="row clearfix">
        <!-- Content Column -->
        <div class="content-column col-lg-6 col-md-12 col-sm-12">
          <div class="inner-column">
            <div class="title">
              Cyber Security
            </div>
            <h1>Cyber <br> security</h1>
            <div class="bold-text">
              Protect your workspace
            </div>
            <div class="text">
              We have a proven track record providing expert cyber security recruitment services to businesses of all sizes.
            </div>
            <div class="btns-box">
              <a
                href="about.html"
                class="theme-btn btn-style-six"
              ><span class="txt">Read More</span></a>
              <a
                href="case-detail.html"
                class="theme-btn btn-style-seven"
              ><span class="txt">Cyber Solution</span></a>
            </div>
          </div>
        </div>
				
        <!-- Image Column -->
        <div class="image-column col-lg-6 col-md-12 col-sm-12">
          <div class="inner-column">
            <div
              class="image"
              data-tilt
              data-tilt-max="4"
            >
              <img
                src="~@/assets/images/main-slider/image-4.png"
                alt=""
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- End Banner Section Three -->
	
  <!-- About Section -->
  <section class="about-section">
    <div
      class="pattern-layer-one"
      :style="{'background-image': `url(${require('@/assets/images/background/pattern-16.png')})`}"
    />
    <div
      class="pattern-layer-two"
      :style="{'background-image': `url(${require('@/assets/images/background/pattern-17.png')})`}"
    />
    <div
      class="pattern-layer-three"
      :style="{'background-image': `url(${require('@/assets/images/background/pattern-18.png')})`}"
    />
    <div
      class="pattern-layer-four"
      :style="{'background-image': `url(${require('@/assets/images/background/pattern-19.png')})`}"
    />
    <div class="auto-container">
      <div class="row clearfix">
        <!-- Image Column -->
        <div class="image-column col-lg-6 col-md-12 col-sm-12">
          <div
            class="inner-column wow fadeInLeft"
            data-wow-delay="0ms"
            data-wow-duration="1500ms"
          >
            <div class="upper-image">
              <img
                src="~@/assets/images/resource/about-1.jpg"
                alt=""
              >
            </div>
            <div class="row clearfix">
              <!-- Count Column -->
              <div class="count-column col-lg-6 col-md-6 col-sm-12">
                <div class="counter-inner">
                  <div class="count-outer count-box">
                    <span
                      class="count-text"
                      data-speed="3500"
                      data-stop="25"
                    >0</span> +
                    <i>Yeae year of <br> working experience</i>
                  </div>
                </div>
              </div>
              <!-- Lower Image Column -->
              <div class="lower-image-column col-lg-6 col-md-6 col-sm-12">
                <div class="image">
                  <img
                    src="~@/assets/images/resource/about-2.png"
                    alt=""
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
				
        <!-- Content Column -->
        <div class="content-column col-lg-6 col-md-12 col-sm-12">
          <div
            class="inner-column wow fadeInRight"
            data-wow-delay="0ms"
            data-wow-duration="1500ms"
          >
            <!-- Sec Title Two -->
            <div class="sec-title-two">
              <div class="title">
                ABOUT COMPANY
              </div>
              <h2>We Detect The Threats <br> You Can't See</h2>
              <div class="text">
                This apartment is 680 square feet & studio type with one bedroom, big living room and one bathroom & also wardrobe who do not know how to pursue pleasure.
              </div>
            </div>
            <!-- End Sec Title Two -->
						
            <!-- About List -->
            <ul class="feature-list">
              <li>
                <span class="icon flaticon-safe" />
                <strong>Security Operations </strong>
                This apartment is 680 square feet & studio type with one bedroom,big living room 
              </li>
              <li>
                <span class="icon flaticon-padlock" />
                <strong>Application Security </strong>
                This apartment is 680 square feet & studio type with one bedroom,big living room 
              </li>
            </ul>
            <!-- End About List -->
						
            <!-- Lower Box -->
            <div class="lower-box">
              Your digital workflow be <br> productive peduce risk
              <a
                href="#"
                class="theme-btn read-more"
              >Read More</a>
            </div>
            <!-- End Lower Box -->
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- End About Section -->
	
  <!-- Services Section Three -->
  <section class="services-section-three">
    <div class="auto-container">
      <div class="row clearfix">
        <!-- Service Block Three -->
        <div class="service-block-three col-lg-4 col-md-6 col-sm-12">
          <div
            class="inner-box wow fadeInLeft"
            data-wow-delay="0ms"
            data-wow-duration="1500ms"
          >
            <div class="icon">
              <img
                src="~@/assets/images/resource/service-4.png"
                alt=""
              >
            </div>
            <h3><a href="service-detail.html">A Handcrafted Approach</a></h3>
            <div class="text">
              Lorem ipsum dolor sit amet, conste adipiscing elit nam hendrerit nisi sed security sollicitudin.
            </div>
          </div>
        </div>
				
        <!-- Service Block Three -->
        <div class="service-block-three col-lg-4 col-md-6 col-sm-12">
          <div
            class="inner-box wow fadeInUp"
            data-wow-delay="0ms"
            data-wow-duration="1500ms"
          >
            <div class="icon">
              <img
                src="~@/assets/images/resource/service-5.png"
                alt=""
              >
            </div>
            <h3><a href="service-detail.html">Cyber Security Architects</a></h3>
            <div class="text">
              Lorem ipsum dolor sit amet, conste adipiscing elit nam hendrerit nisi sed security sollicitudin.
            </div>
          </div>
        </div>
				
        <!-- Service Block Three -->
        <div class="service-block-three col-lg-4 col-md-6 col-sm-12">
          <div
            class="inner-box wow fadeInRight"
            data-wow-delay="0ms"
            data-wow-duration="1500ms"
          >
            <div class="icon">
              <img
                src="~@/assets/images/resource/service-6.png"
                alt=""
              >
            </div>
            <h3><a href="service-detail.html">Security Fingerprint</a></h3>
            <div class="text">
              Lorem ipsum dolor sit amet, conste adipiscing elit nam hendrerit nisi sed security sollicitudin.
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- End Services Section Three -->
	
  <!-- Services Section Four -->
  <section
    class="services-section-four"
    :style="{'background-image': `url(${require('@/assets/images/background/pattern-20.png')})`}"
  >
    <div class="auto-container">
      <!-- Sec Title Two -->
      <div class="sec-title-two centered">
        <div class="title">
          OUR SERVICES
        </div>
        <h2>High Quality Trusted Cyber Security</h2>
      </div>
      <!-- End Sec Title Two -->
			
      <div class="row clearfix">
        <!-- Service Block Four -->
        <div class="service-block-four col-lg-4 col-md-6 col-sm-12">
          <div
            class="inner-box wow fadeInLeft"
            data-wow-delay="0ms"
            data-wow-duration="1500ms"
          >
            <div class="icon">
              <img
                src="~@/assets/images/resource/service-7.png"
                alt=""
              >
            </div>
            <h3><a href="service-detail.html">Cloud Security</a></h3>
            <div class="text">
              Lorem Ipsum, you need to be sure there isn't anything embarrassing  generators on the Internet
            </div>
            <a
              href="service-detail.html"
              class="explore"
            >Explore</a>
          </div>
        </div>
				
        <!-- Service Block Four -->
        <div class="service-block-four col-lg-4 col-md-6 col-sm-12">
          <div
            class="inner-box wow fadeInUp"
            data-wow-delay="0ms"
            data-wow-duration="1500ms"
          >
            <div class="icon">
              <img
                src="~@/assets/images/resource/service-8.png"
                alt=""
              >
            </div>
            <h3><a href="service-detail.html">Network Monitoring</a></h3>
            <div class="text">
              Lorem Ipsum, you need to be sure there isn't anything embarrassing  generators on the Internet
            </div>
            <a
              href="service-detail.html"
              class="explore"
            >Explore</a>
          </div>
        </div>
				
        <!-- Service Block Four -->
        <div class="service-block-four col-lg-4 col-md-6 col-sm-12">
          <div
            class="inner-box wow fadeInRight"
            data-wow-delay="0ms"
            data-wow-duration="1500ms"
          >
            <div class="icon">
              <img
                src="~@/assets/images/resource/service-9.png"
                alt=""
              >
            </div>
            <h3><a href="service-detail.html">Penetration Testing</a></h3>
            <div class="text">
              Lorem Ipsum, you need to be sure there isn't anything embarrassing  generators on the Internet
            </div>
            <a
              href="service-detail.html"
              class="explore"
            >Explore</a>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- End Services Section Four -->
	
  <!-- CTA Section -->
  <section class="cta-section">
    <div class="auto-container">
      <div class="inner-container">
        <div class="row clearfix">
          <!-- Title Column -->
          <div class="title-column col-lg-8 col-md-12 col-sm-12">
            <div class="inner-column">
              <h2>Why Foresite is a Leader Among Cyber Security as a Service Providers</h2>
            </div>
          </div>
					
          <!-- Security Column -->
          <div class="security-column col-lg-4 col-md-12 col-sm-12">
            <div class="inner-column">
              <div class="percent">
                100%
              </div>
              <div class="text">
                Onshore security team <br> with no outsourcing
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- End CTA Section -->
	
  <!-- Pricing Section / Style Two -->
  <section class="pricing-section style-two">
    <div
      class="pattern-layer-one"
      :style="{'background-image': `url(${require('@/assets/images/background/pattern-21.png')})`}"
    />
    <div
      class="pattern-layer-two"
      :style="{'background-image': `url(${require('@/assets/images/background/pattern-22.png')})`}"
    />
    <div class="auto-container">
      <!-- Sec Title Two -->
      <div class="sec-title-two centered">
        <div class="title">
          OUR Pricing
        </div>
        <h2>Affordable price plans for you!</h2>
      </div>
      <!-- End Sec Title Two -->
			
      <div class="pricing-tabs tabs-box">
        <!--Tab Btns-->
        <div class="buttons-outer">
          <ul class="tab-buttons clearfix">
            <li
              data-tab="#prod-monthly"
              class="tab-btn active-btn"
            >
              Per Month
            </li>
            <li
              data-tab="#prod-yearly"
              class="tab-btn"
            >
              Yearly
            </li>
          </ul>
        </div>
				
        <!--Tabs Container-->
        <div class="tabs-content">
          <!-- Tab -->
          <div
            id="prod-monthly"
            class="tab active-tab"
          >
            <div class="content">
              <div class="row clearfix">
                <!-- Price Block -->
                <div class="price-block col-lg-4 col-md-6 col-sm-12">
                  <div class="inner-box">
                    <div class="upper-box">
                      <div class="title">
                        BASIC
                      </div>
                      <div class="price">
                        $29 <span>Per month.</span>
                      </div>
                    </div>
                    <div class="lower-box">
                      <ul class="offer-list">
                        <li>Unlimited project</li>
                        <li>24/7 Support</li>
                        <li>Reporting</li>
                        <li>Customer Success</li>
                      </ul>
                      <div class="btn-box text-center">
                        <a
                          href="case-detail.html"
                          class="theme-btn apply-btn"
                        >Apply Now</a>
                      </div>
                      <div class="trial">
                        Get 30 day free trail
                      </div>
                    </div>
                  </div>
                </div>
								
                <!-- Price Block -->
                <div class="price-block col-lg-4 col-md-6 col-sm-12">
                  <div class="inner-box">
                    <div class="title-box">
                      Best
                    </div>
                    <div class="upper-box">
                      <div class="title">
                        PROFESSIONAL
                      </div>
                      <div class="price">
                        $69 <span>Per month.</span>
                      </div>
                    </div>
                    <div class="lower-box">
                      <ul class="offer-list">
                        <li>All Essential Features</li>
                        <li>Webinar Integration</li>
                        <li>Zapier Integration</li>
                        <li>Access to SSO / API</li>
                        <li>Multiple Languages</li>
                      </ul>
                      <div class="btn-box text-center">
                        <a
                          href="case-detail.html"
                          class="theme-btn apply-btn"
                        >Apply Now</a>
                      </div>
                      <div class="trial">
                        Get 30 day free trail
                      </div>
                    </div>
                  </div>
                </div>
								
                <!-- Price Block -->
                <div class="price-block col-lg-4 col-md-6 col-sm-12">
                  <div class="inner-box">
                    <div class="upper-box">
                      <div class="title">
                        ADVANCED
                      </div>
                      <div class="price">
                        $49 <span>Per month.</span>
                      </div>
                    </div>
                    <div class="lower-box">
                      <ul class="offer-list">
                        <li>Unlimited project</li>
                        <li>24/7 Support</li>
                        <li>Reporting</li>
                        <li>Customer Success</li>
                      </ul>
                      <div class="btn-box text-center">
                        <a
                          href="case-detail.html"
                          class="theme-btn apply-btn"
                        >Apply Now</a>
                      </div>
                      <div class="trial">
                        Get 30 day free trail
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
					
          <!--Tab-->
          <div
            id="prod-yearly"
            class="tab"
          >
            <div class="content">
              <div class="row clearfix">
                <!-- Price Block -->
                <div class="price-block col-lg-4 col-md-6 col-sm-12">
                  <div class="inner-box">
                    <div class="upper-box">
                      <div class="title">
                        BASIC
                      </div>
                      <div class="price">
                        $29 <span>Per month.</span>
                      </div>
                    </div>
                    <div class="lower-box">
                      <ul class="offer-list">
                        <li>Unlimited project</li>
                        <li>24/7 Support</li>
                        <li>Reporting</li>
                        <li>Customer Success</li>
                      </ul>
                      <div class="btn-box text-center">
                        <a
                          href="case-detail.html"
                          class="theme-btn apply-btn"
                        >Apply Now</a>
                      </div>
                      <div class="trial">
                        Get 30 day free trail
                      </div>
                    </div>
                  </div>
                </div>
								
                <!-- Price Block -->
                <div class="price-block col-lg-4 col-md-6 col-sm-12">
                  <div class="inner-box">
                    <div class="title-box">
                      Best
                    </div>
                    <div class="upper-box">
                      <div class="title">
                        PROFESSIONAL
                      </div>
                      <div class="price">
                        $69 <span>Per month.</span>
                      </div>
                    </div>
                    <div class="lower-box">
                      <ul class="offer-list">
                        <li>All Essential Features</li>
                        <li>Webinar Integration</li>
                        <li>Zapier Integration</li>
                        <li>Access to SSO / API</li>
                        <li>Multiple Languages</li>
                      </ul>
                      <div class="btn-box text-center">
                        <a
                          href="case-detail.html"
                          class="theme-btn apply-btn"
                        >Apply Now</a>
                      </div>
                      <div class="trial">
                        Get 30 day free trail
                      </div>
                    </div>
                  </div>
                </div>
								
                <!-- Price Block -->
                <div class="price-block col-lg-4 col-md-6 col-sm-12">
                  <div class="inner-box">
                    <div class="upper-box">
                      <div class="title">
                        ADVANCED
                      </div>
                      <div class="price">
                        $49 <span>Per month.</span>
                      </div>
                    </div>
                    <div class="lower-box">
                      <ul class="offer-list">
                        <li>Unlimited project</li>
                        <li>24/7 Support</li>
                        <li>Reporting</li>
                        <li>Customer Success</li>
                      </ul>
                      <div class="btn-box text-center">
                        <a
                          href="case-detail.html"
                          class="theme-btn apply-btn"
                        >Apply Now</a>
                      </div>
                      <div class="trial">
                        Get 30 day free trail
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- End Pricing Section -->
	
  <!-- Protect Section -->
  <section class="protect-section">
    <div
      class="pattern-layer-one"
      :style="{'background-image': `url(${require('@/assets/images/background/pattern-23.png')})`}"
    />
    <div
      class="pattern-layer-two"
      :style="{'background-image': `url(${require('@/assets/images/background/pattern-24.png')})`}"
    />
    <div
      class="pattern-layer-three"
      :style="{'background-image': `url(${require('@/assets/images/background/pattern-25.png')})`}"
    />
    <div class="auto-container">
      <div class="row clearfix">
        <!-- Content Column -->
        <div class="content-column col-lg-6 col-md-12 col-sm-12">
          <div class="inner-column">
            <!-- Sec Title Two -->
            <div class="sec-title-two">
              <div class="title">
                Protect your environment
              </div>
              <h2>Partner With One Of The Premier Cyber Security As A Service Providers</h2>
              <div class="text">
                Dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident sunt in culpa
              </div>
            </div>
            <!-- End Sec Title Two -->
						
            <!-- Fact Counter -->
            <div class="fact-counter-three">
              <div class="row clearfix">
                <!-- Column -->
                <div class="column counter-column col-lg-6 col-md-6 col-sm-12">
                  <div
                    class="inner wow fadeInLeft"
                    data-wow-delay="0ms"
                    data-wow-duration="1500ms"
                  >
                    <div class="content">
                      <div class="count-outer count-box">
                        <span
                          class="count-text"
                          data-speed="3500"
                          data-stop="6"
                        >0</span>k+
                      </div>
                      <div class="counter-title">
                        Succeeded projects
                      </div>
                      <div class="text">
                        Sint occaecat cupidatat non proident sunt in culpa qui officia
                      </div>
                    </div>
                  </div>
                </div>

                <!--Column-->
                <div class="column counter-column col-lg-6 col-md-6 col-sm-12">
                  <div
                    class="inner wow fadeInLeft"
                    data-wow-delay="300ms"
                    data-wow-duration="1500ms"
                  >
                    <div class="content">
                      <div class="count-outer count-box">
                        <span
                          class="count-text"
                          data-speed="2500"
                          data-stop="7"
                        >0</span>k+
                      </div>
                      <div class="counter-title">
                        Happy Client
                      </div>
                      <div class="text">
                        Sint occaecat cupidatat non proident sunt in culpa qui officia
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- End Fact Counter -->
          </div>
        </div>
				
        <!-- Image Column -->
        <div class="image-column col-lg-6 col-md-12 col-sm-12">
          <div class="inner-column">
            <div class="image">
              <img
                src="~@/assets/images/resource/case-8.jpg"
                alt=""
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- End Protect Section -->
	
  <TestimonialCarousel />
	
  <ContactWidget />
</template>

<script>

import TestimonialCarousel from '../components/TestimonialCarousel.vue'
import ContactWidget from '../components/ContactWidget.vue'

export default {
  name: 'Home',
  components: {
    TestimonialCarousel,
    ContactWidget
  },
  mounted() {
    initializeJQuery();
  }
}
</script>

<style>
</style>
