<template>
  <section class="page-title-section style-five">
    <div class="left-color-layer" />
    <div class="right-color-layer" />
    <div
      class="icon-layer-one"
      :style="{'background-image': `url(${require('@/assets/images/icons/icon-1.png')})`}"
    />
    <div
      class="icon-layer-two"
      :style="{'background-image': `url(${require('@/assets/images/icons/icon-4.png')})`}"
    />
    <div
      class="icon-layer-three"
      :style="{'background-image': `url(${require('@/assets/images/icons/icon-1.png')})`}"
    />
    <div
      class="icon-layer-four"
      :style="{'background-image': `url(${require('@/assets/images/icons/icon-12.png')})`}"
    />
    <div
      class="icon-layer-five"
      :style="{'background-image': `url(${require('@/assets/images/icons/icon-10.png')})`}"
    />
    <div class="auto-container">
      <div class="content-boxed">
        <div class="inner-box">
          <div class="title">
            {{ $t('pages.contact.title') }}
          </div>
          <h2>{{ $t('pages.contact.slogan') }}</h2>
          <div class="text">
            {{ $t('pages.contact.subtitle') }}
          </div>
        </div>
      </div>
    </div>
  </section>
    
  <section class="contact-page-section">
    <div class="auto-container">
      <div class="inner-container">
        <div class="row clearfix">
          <div class="info-block col-lg-4 col-md-12 col-sm-12">
            <div class="inner-column">
              <ul class="list">
                <li>
                  <span class="icon"><img
                    src="~@/assets/images/icons/map.png"
                    alt=""
                  ></span>
                  <strong>{{ $t("pages.contact.form.address") }}</strong>
                  <p>
                    dotManaged<br>
                    Inhaber Christopher Mäuer<br>
                    Hedwig-Porschütz-Straße 30<br>
                    10557 Berlin
                  </p>
                </li>
                <li>
                  <span class="icon" />
                  <strong>{{ $t("pages.contact.form.email") }}</strong>
                  <a href="mailto:info@dotmanaged.eu">info@dotmanaged.eu</a>
                </li>
                <li>
                  <span class="icon" />
                  <strong>{{ $t("pages.contact.form.phone") }}</strong>
                  <a href="tel:+493085627629">+49 30 856 27 629</a>
                </li>
                <li>
                  <strong>{{ $t("pages.contact.form.social") }}</strong>
                  <a
                    href="https://facebook.com/dotManaged"
                    target="_blank"
                    class="fa fa-facebook-f"
                  />
                  <a
                    href="https://twitter.com/dotManaged"
                    target="_blank"
                    class="fa fa-twitter"
                  />
                  <a
                    href="https://instagram.com/dotManaged"
                    target="_blank"
                    class="fa fa-instagram"
                  />
                  <a
                    href="https://linkedin.com/dotManaged"
                    target="_blank"
                    class="fa fa-linkedin"
                  />
                </li>
              </ul>
            </div>
          </div>
					
          <div class="form-block col-lg-8 col-md-12 col-sm-12">
            <div class="inner-column">
              <div class="title-box">
                <h3>{{ $t("pages.contact.form.title") }}</h3>
                <div class="text">
                  {{ $t("pages.contact.form.text") }}
                </div>
              </div>
							
              <div class="calendly-plugin">
                <a
                  href="https://calendly.com/dotmanaged"
                  target="_blank"
                  class="theme-btn btn-style-one btn-fullwidth"
                ><span class="txt">{{ $t("pages.contact.form.calendly.button") }} <span class="fa fa-external-link" /></span></a>
                <div class="divider">
                  <span>{{ $t("pages.contact.form.calendly.divider") }}</span>
                </div>
              </div>

              <div class="contact-form">
                <form
                  id="contact-form"
                  method="post"
                  action="sendemail.php"
                >
                  <div class="row clearfix">
                    <div class="form-group col-lg-6 col-md-6 col-sm-12">
                      <input
                        type="text"
                        :placeholder="$t('pages.contact.form.inputs.name')"
                        required
                      >
                    </div>
										
                    <div class="form-group col-lg-6 col-md-6 col-sm-12">
                      <input
                        type="text"
                        :placeholder="$t('pages.contact.form.inputs.company')"
                      >
                    </div>
										
                    <div class="form-group col-lg-6 col-md-6 col-sm-12">
                      <input
                        type="email"
                        :placeholder="$t('pages.contact.form.inputs.email')"
                        required
                      >
                    </div>
										
                    <div class="form-group col-lg-6 col-md-6 col-sm-12">
                      <input
                        type="phone"
                        :placeholder="$t('pages.contact.form.inputs.phone')"
                      >
                    </div>
										
                    <div class="form-group col-lg-12 col-md-12 col-sm-12">
                      <textarea
                        name="message"
                        :placeholder="$t('pages.contact.form.inputs.message')"
                      />
                    </div>
									
                    <div class="form-group col-lg-12 col-md-12 col-sm-12">
                      <button
                        class="theme-btn submit-btn"
                        type="submit"
                        name="submit-form"
                      >
                        {{ $t("pages.contact.form.button") }}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
    
  <section class="discuss-section">
    <div class="auto-container">
      <div class="inner-container">
        <div class="color-layer" />
        <div class="row clearfix">
          <div class="title-column col-lg-8 col-md-12 col-sm-12">
            <div class="inner-column">
              <h3 v-html="$t('pages.contact.banner.text')" />
              <a
                href="#"
                class="theme-btn btn-style-one"
              ><span class="txt">{{ $t('pages.contact.banner.button') }}</span></a>
            </div>
          </div>
					
          <div class="image-column col-lg-4 col-md-12 col-sm-12">
            <div class="inner-column">
              <div class="image">
                <img
                  src="~@/assets/images/resource/discuss.png"
                  alt=""
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>

export default {
  name: 'Contact',
  components: {
  },
  mounted() {
  }
}
</script>

<style>
.btn-fullwidth {
    width: 100%;
    text-align: center;
}

.calendly-plugin .divider {
   width: 100%; 
   text-align: center; 
   border-bottom: 1px dotted #777777; 
   line-height: 0.1em;
   margin: 20px 0 30px; 
}

.calendly-plugin .divider span {
    background:#fff; 
    padding:0 10px; 
}
</style>
