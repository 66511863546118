export default {
  "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["hello ", _interpolate(_named("company")), "!!"])},
  "page-header": {
    "contact-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get in touch"])}
  },
  "page-footer": {
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address"])},
    "services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our Services"])},
    "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontakt per E-Mail"])},
    "call": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rufen Sie uns an"])}
  },
  "components": {
    "testimonial-carousel": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We achieve common goals"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our comprehensive support, from project planning to implementation and aftercare, creates a productive and familiar working relationship, enabling us to achieve our common goals more quickly."])}
    },
    "contact-widget": {
      "slogan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Say hi!"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send us a message"])},
      "message-placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message or inquiry"])},
      "name-placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name, Company"])},
      "phone-placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone number"])},
      "email-placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["eMail address"])}
    }
  },
  "pages": {
    "contact": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact"])},
      "link-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact"])},
      "slogan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leave a message"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leave us a little info, and we’ll be in touch very soon."])}
    },
    "legal": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legal"])},
      "link-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legal"])}
    },
    "tncs": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terms & Conditions"])},
      "link-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["T&Cs"])}
    },
    "imprint": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Imprint"])},
      "link-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Imprint"])}
    },
    "about": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About"])},
      "link-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About us"])}
    },
    "team": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Out Team"])},
      "link-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Team"])}
    },
    "pricing": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our Pricing Scheme"])},
      "link-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pricing"])}
    },
    "testimonials": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What our Customers say"])},
      "link-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer Reviews"])}
    },
    "consulting": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comprehensive IT Consulting"])},
      "link-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consulting"])}
    },
    "cloud-services": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We take your Applications to the Cloud"])},
      "link-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cloud-Services"])}
    },
    "managed-services": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wir manage your IT Systems"])},
      "link-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Managed Services"])}
    },
    "managed-kubernetes": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Managed Kubernetes"])},
      "link-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Managed Kubernetes"])}
    },
    "managed-plesk": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Managed Plesk"])},
      "link-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Managed Plesk"])}
    },
    "domain-registration-api": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domain Registration API"])},
      "link-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domain Registration API"])}
    },
    "it-analysis": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IT Analysis"])},
      "link-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IT Analysis"])}
    },
    "devops": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DevOps"])},
      "link-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DevOps"])}
    },
    "it-administration": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IT System Administration"])},
      "link-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IT System Administration"])}
    },
    "it-project-engineering": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IT Project Management"])},
      "link-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IT Project Management"])}
    },
    "not-found": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["404"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Page could not be found"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We are sorry, the page you requested could not be found.<br>Perhaps it has been deleted, moved, or is just temporarily unavailable."])},
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back to homepage"])}
    }
  }
}