<template>
  <section class="error-section">
    <div class="auto-container">
      <div class="content">
        <h1>{{ $t('pages.not-found.title') }}</h1>
        <h2>{{ $t('pages.not-found.subtitle') }}</h2>
        <div
          class="text"
          v-html="$t('pages.not-found.text')"
        />
        <router-link
          to="/"
          class="theme-btn btn-style-three"
        >
          <span class="txt">{{ $t('pages.not-found.button') }}</span>
        </router-link>
      </div>
    </div>
  </section>
	
  <ContactWidget />
</template>

<script>

import ContactWidget from '../components/ContactWidget.vue'

export default {
  name: 'NotFound',
  components: {
    ContactWidget
  },
  mounted() {
  }
}
</script>

<style>
</style>
