<template>
  <section class="address-section">
    <div class="auto-container">
      <div class="inner-container">
        <div class="content">
          <div class="icon">
            <img
              src="~@/assets/images/icons/message.png"
              alt=""
            >
          </div>
          <div class="title">
            {{ $t("components.contact-widget.slogan") }}
          </div>
          <h2>{{ $t("components.contact-widget.title") }}</h2>
					
          <!-- Subscribe Form -->
          <div class="subscribe-form">
            <form
              method="post"
              action="contact.html"
            >
              <div class="form-group">
                <textarea
                  :placeholder="$t('components.contact-widget.message-placeholder') + ' *'"
                />
              </div>
              <div class="form-group">
                <input
                  type="text"
                  value=""
                  :placeholder="$t('components.contact-widget.name-placeholder') + ' *'"
                  required
                >
              </div>
              <div class="form-group">
                <input
                  type="phone"
                  value=""
                  :placeholder="$t('components.contact-widget.phone-placeholder')"
                >
              </div>
              <div class="form-group">
                <input
                  type="email"
                  value=""
                  :placeholder="$t('components.contact-widget.email-placeholder') + ' *'"
                  required
                >
                <button type="submit">
                  <span class="fa fa-arrow-right" />
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>

export default {
  name: 'ContactWidget',
  components: {
  },
  data() {
      return {
          testimonials: [
              {
                  author: "Tobias Roloff",
                  company: "AMS Gruppe",
                  message: {
                      de: "Langjährige hervorragende Zusammenarbeit. Immer ein Gewinn Herrn Mäuer im Team zu haben. Bei zukünftigen Projekten jederzeit wieder.",
                      en: "Many years of excellent cooperation. Always a benefit to have Mr. Mäuer in the team. Anytime again for future projects."
                  },
                  image: "ams-gruppe.jpeg"
              },
              {
                  author: "Luca Koroll",
                  company: "Marketing-Experte",
                  message: {
                      de: "Top Premium Hosting-Dienstleister aus Berlin. Schneller und freundlicher Kundensupport, der kompetent auf Probleme reagiert. Ich bin bereits seit 2014 glücklicher Kunde und kann mir keinen besseren Hosting-Provider vorstellen. Denn es werden auch individuelle Lösungen für Probleme gefunden - man ist mehr als nur eine Ticketnummer!",
                      en: "Top premium hosting service provider from Berlin. Fast and friendly customer support that reacts competently to problems. I'm already a happy customer since 2014 and can't imagine a better hosting provider. Because individual solutions for problems are also found - you are more than just a ticket number!"
                  },
                  image: "LucaKoroll-Logo-BlackTransp-medium.png"
              }
          ]
      };
  },
  mounted() {
    initializeJQuery();
  }
}
</script>

<style>
</style>
