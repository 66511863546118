<template>
  <footer class="footer-style-two">
    <div class="color-layer" />
    <div
      class="pattern-layer-one"
      :style="{'background-image': `url(${require('@/assets/images/icons/icon-6.png')})`}"
    />
    <div
      class="pattern-layer-two"
      :style="{'background-image': `url(${require('@/assets/images/icons/icon-1.png')})`}"
    />
    <div
      class="pattern-layer-three"
      :style="{'background-image': `url(${require('@/assets/images/icons/icon-7.png')})`}"
    />
    <div
      class="pattern-layer-four"
      :style="{'background-image': `url(${require('@/assets/images/icons/icon-8.png')})`}"
    />
    <div class="auto-container">
      <!--Widgets Section-->
      <div class="widgets-section">
        <div class="row clearfix">
          <!-- Footer Column -->
          <div class="footer-column col-lg-3 col-md-6 col-sm-12">
            <div class="footer-widget logo-widget">
              <div class="logo">
                <router-link to="/">
                  <img
                    src="@/assets/images/logo_light.svg"
                    alt="dotManaged Logo"
                  >
                </router-link>
              </div>
              <div class="text">
                <p>
                  dotManaged - Inhaber Christopher Mäuer<br>
                  Hedwig-Porschütz-Straße 30<br>
                  10557 Berlin
                </p>
              </div>
            </div>
          </div>
					
          <!-- Footer Column -->
          <div class="footer-column col-lg-3 col-md-6 col-sm-12">
            <div class="footer-widget list-widget">
              <h4>{{ $t('page-footer.services') }}</h4>
              <ul class="list">
                <li>
                  <router-link to="/consulting/it-analysis">
                    {{ $t('pages.it-analysis.link-title') }}
                  </router-link>
                </li>
                <li>
                  <router-link to="/consulting/devops">
                    {{ $t('pages.devops.link-title') }}
                  </router-link>
                </li>
                <li>
                  <router-link to="/consulting/it-administration">
                    {{ $t('pages.it-administration.link-title') }}
                  </router-link>
                </li>
                <li>
                  <router-link to="/consulting/it-project-engineering">
                    {{ $t('pages.it-project-engineering.link-title') }}
                  </router-link>
                </li>
                <li>
                  <router-link to="/cloud-services/domain-registration-api">
                    {{ $t('pages.domain-registration-api.link-title') }}
                  </router-link>
                </li>
                <li>
                  <router-link to="/managed-services/kubernetes">
                    {{ $t('pages.managed-kubernetes.link-title') }}
                  </router-link>
                </li>
                <li>
                  <router-link to="/managed-services/plesk">
                    {{ $t('pages.managed-plesk.link-title') }}
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
					
          <!-- Footer Column -->
          <div class="footer-column col-lg-3 col-md-6 col-sm-12">
            <div class="footer-widget list-widget">
              <h4>{{ $t('page-footer.company') }}</h4>
              <ul class="list">
                <li>
                  <router-link to="/about/team">
                    {{ $t('pages.team.link-title') }}
                  </router-link>
                </li>
                <li>
                  <router-link to="/about/pricing">
                    {{ $t('pages.pricing.link-title') }}
                  </router-link>
                </li>
                <li>
                  <router-link to="/about/testimonials">
                    {{ $t('pages.testimonials.link-title') }}
                  </router-link>
                </li>
                <li>
                  <router-link to="/contact">
                    {{ $t('pages.contact.link-title') }}
                  </router-link>
                </li>
                <li>
                  <router-link to="/legal/tncs">
                    {{ $t('pages.tncs.link-title') }}
                  </router-link>
                </li>
                <li>
                  <router-link to="/legal/imprint">
                    {{ $t('pages.imprint.link-title') }}
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
					
          <!-- Footer Column -->
          <div class="footer-column col-lg-3 col-md-6 col-sm-12">
            <div class="footer-widget contact-info-widget">
              <ul class="address-list">
                <li><span>{{ $t('page-footer.email') }}</span><a href="mailto:info@dotmanaged.eu">info@dotmanaged.eu</a></li>
                <li><span>{{ $t('page-footer.call') }}</span><a href="tel:+493085627629">+49 30 856 27 629</a></li>
              </ul>
              <!-- Social Box -->
              <ul class="social-box">
                <li>
                  <a
                    href="https://facebook.com/dotManaged"
                    target="_blank"
                    class="fa fa-facebook-f"
                  />
                </li>
                <li>
                  <a
                    href="https://twitter.com/dotManaged"
                    target="_blank"
                    class="fa fa-twitter"
                  />
                </li>
                <li>
                  <a
                    href="https://instagram.com/dotManaged"
                    target="_blank"
                    class="fa fa-instagram"
                  />
                </li>
                <li>
                  <a
                    href="https://linkedin.com/dotManaged"
                    target="_blank"
                    class="fa fa-linkedin"
                  />
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
			
      <!-- Footer Bottom -->
      <div class="footer-bottom text-center">
        <div class="copyright">
          Copyright 2015 - {{ (new Date()).getFullYear() }}, All Rights Reserved
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'PageFooter',
  props: {},
  data() {
	  	return {
		  	locales: process.env.VUE_APP_I18N_SUPPORTED_LOCALE.split(','),
		  	currentLocale: this.$i18n.locale
	  	}
  },
  methods: {
	  	switchLocale(newLocale) {
		  	this.$i18n.locale = newLocale
			this.currentLocale = this.$i18n.locale
			localStorage.setItem('locale', newLocale)
	 	}
  }
}
</script>

<style scoped>
.logo img {
    width: 180px;
}
</style>
