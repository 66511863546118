import { createApp } from 'vue'
import { createRouter, createWebHistory } from 'vue-router'
import i18n from './i18n'
import App from './App.vue'

import Home from './pages/Home.vue'
import About from './pages/About.vue'
import Team from './pages/Team.vue'
import Pricing from './pages/Pricing.vue'
import Testimonials from './pages/Testimonials.vue'
import ITAnalysis from './pages/ITAnalysis.vue'
import DevOps from './pages/DevOps.vue'
import ITAdministration from './pages/ITAdministration.vue'
import ITProjectEngineering from './pages/ITProjectEngineering.vue'
import DomainRegistrationAPI from './pages/DomainRegistrationAPI.vue'
import ManagedKubernetes from './pages/ManagedKubernetes.vue'
import ManagedPlesk from './pages/ManagedPlesk.vue'
import Legal from './pages/Legal.vue'
import Tncs from './pages/Tncs.vue'
import Imprint from './pages/Imprint.vue'
import Contact from './pages/Contact.vue'
import NotFound from './pages/NotFound.vue'

const routes = [
  { path: '/', component: Home },
  { path: '/about', component: About },
  { path: '/about/team', component: Team },
  { path: '/about/pricing', component: Pricing },
  { path: '/about/testimonials', component: Testimonials },
  { path: '/consulting/it-analysis', component: ITAnalysis },
  { path: '/consulting/devops', component: DevOps },
  { path: '/consulting/it-administration', component: ITAdministration },
  { path: '/consulting/it-project-engineering', component: ITProjectEngineering },
  { path: '/cloud-services/domain-registration-api', component: DomainRegistrationAPI },
  { path: '/managed-services/kubernetes', component: ManagedKubernetes },
  { path: '/managed-services/plesk', component: ManagedPlesk },
  { path: '/legal', component: Legal },
  { path: '/legal/tncs', component: Tncs },
  { path: '/legal/imprint', component: Imprint },
  { path: '/contact', component: Contact },
  { path: '/:catchAll(.*)', component: NotFound },
]

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior(to, from, savedPosition) {
      return { top: 0 }
    },
  })

createApp(App).use(i18n).use(router).mount('#app')
