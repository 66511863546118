<template>
  <p>
    ITAdministration
  </p>
</template>

<script>

export default {
  name: 'ITAdministration',
  components: {
  },
  mounted() {
  }
}
</script>

<style>
</style>
