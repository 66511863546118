export default {
  "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["hallo ", _interpolate(_named("company")), "!!"])},
  "page-header": {
    "contact-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontakt aufnehmen"])}
  },
  "page-footer": {
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anschrift"])},
    "services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unsere Leistungen"])},
    "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unternehmen"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontakt per E-Mail"])},
    "call": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rufen Sie uns an"])}
  },
  "components": {
    "testimonial-carousel": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wir erreichen mit unseren Kund*innen gemeinsame Ziele"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durch unsere umfassende Betreuung von der Projektierung über die Umsetzung bis zur Nachbetreuung schaffen wir eine produktive und vertraute Zusammenarbeit und erreichen so unsere gemeinsamen Ziele schneller."])}
    },
    "contact-widget": {
      "slogan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gibt es Fragen?"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Senden Sie uns eine Nachricht"])},
      "message-placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihre Nachricht"])},
      "name-placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihr Name"])},
      "phone-placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihre Rufnummer"])},
      "email-placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihre E-Mail-Adresse"])}
    }
  },
  "pages": {
    "contact": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontakt"])},
      "link-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontakt"])},
      "slogan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Senden Sie eine Nachricht"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hinterlassen Sie uns ein paar Informationen, und wir melden uns schnellstmöglich bei Ihnen."])},
      "banner": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lassen Sie uns gemeinsam die richtige digitale<br>IT-Lösung für Ihr Unternehmen finden."])},
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jetzt Kontakt aufnehmen"])}
      },
      "form": {
        "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anschrift"])},
        "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail Adresse"])},
        "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefon"])},
        "social": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Social"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontakt aufnehmen"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sollten Sie Gesprächsbedarf zu einem Projekt haben, laden wir Sie dazu ein, einen Termin über Calendly zu buchen. Bei allgemeinen Fragen, nutzen Sie gerne auch das Kontaktformular."])},
        "calendly": {
          "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bei Calendly einen Termin finden"])},
          "divider": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["oder"])}
        },
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nachricht absenden"])},
        "inputs": {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihr Name"])},
          "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unternehmen"])},
          "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail Adresse"])},
          "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefonnummer"])},
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihre Nachricht"])}
        }
      }
    },
    "legal": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechtliches"])},
      "link-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechtliches"])}
    },
    "tncs": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allgemeine Geschäftsbedingungen"])},
      "link-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AGB"])}
    },
    "imprint": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impressum"])},
      "link-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impressum"])}
    },
    "about": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Über uns"])},
      "link-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Über uns"])}
    },
    "team": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Team"])},
      "link-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Team"])}
    },
    "pricing": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unsere Preisgestaltung"])},
      "link-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preise"])}
    },
    "testimonials": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unsere Kundenmeinungen"])},
      "link-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kundenmeinungen"])}
    },
    "consulting": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Umfassende IT-Beratung"])},
      "link-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consulting"])}
    },
    "cloud-services": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wir bringen Ihre Anwendungen in die Cloud"])},
      "link-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cloud-Services"])}
    },
    "managed-services": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wir verwalten Ihre IT-Systeme"])},
      "link-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Managed Services"])}
    },
    "managed-kubernetes": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Managed Kubernetes"])},
      "link-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Managed Kubernetes"])}
    },
    "managed-plesk": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Managed Plesk"])},
      "link-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Managed Plesk"])}
    },
    "domain-registration-api": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domain Registration API"])},
      "link-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domain Registration API"])}
    },
    "it-analysis": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IT-Analyse"])},
      "link-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IT-Analyse"])}
    },
    "devops": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DevOps"])},
      "link-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DevOps"])}
    },
    "it-administration": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IT-Systemadministration"])},
      "link-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IT-Systemadministration"])}
    },
    "it-project-engineering": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IT-Projektierung"])},
      "link-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IT-Projektierung"])}
    },
    "not-found": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["404"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seite konnte nicht gefunden werden"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es tut uns Leid, die angeforderte Seite konnte nicht gefunden werden.<br>Eventuell wurde sie gelöscht, verschoben oder ist nur zeitweise nicht verfügbar."])},
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zur Startseite"])}
    }
  }
}