<template>
  <div class="page-wrapper">
    <PageHeader />
    <router-view />
    <PageFooter />
  </div>
  <div
    class="scroll-to-top scroll-to-target"
    data-target="html"
  >
    <span class="fa fa-arrow-up" />
  </div>
</template>

<script>
import PageHeader from './ui/PageHeader.vue'
import PageFooter from './ui/PageFooter.vue'

export default {
  name: 'App',
  components: {
    PageHeader,
    PageFooter
  },
  watch:{
    $route (to, from){
      initializeJQuery();
    }
},
  mounted() {
    const preloaders = document.querySelectorAll('.preloader');
    preloaders.forEach(preloader => {
      const seconds = 0.5;
      preloader.style.transition = "opacity "+seconds+"s ease";

      preloader.style.opacity = 0;
      setTimeout(function() {
          preloader.parentNode.removeChild(preloader);
      }, seconds * 1000);
    });
  } 
}
</script>

<style src="@/assets/css/bootstrap.css"></style>
<style src="@/assets/css/style.css"></style>
<style src="@/assets/css/responsive.css"></style>
<style>
</style>
