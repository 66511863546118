<template>
  <p>
    Testimonials
  </p>
</template>

<script>

export default {
  name: 'Testimonials',
  components: {
  },
  mounted() {
  }
}
</script>

<style>
</style>
